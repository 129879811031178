.ant-form {
  label {
    color: #17191E;
    &::before {
      content: '' !important;
    }
  }
  .ant-form-item-label {
    line-height: 0;
  }
  .ant-form-item-label > label {
    line-height: 20px;
  }
  .ant-form-item-label > label::before {
    margin-inline-end: 0 !important;
  }
  input {
    font-size: 16px !important;
    color: #17191E;
  }
  span.ant-input-affix-wrapper {
    padding: 11.5px 16px;
    background: #F7F8FA;
    border-radius: 8px;
    input {
      background: #F7F8FA;

    }
    .ant-input-show-count-suffix {
      color: #9B9FA8;
    }

  }
  textarea {
    background: #F7F8FA !important;
    min-height: 174px !important;
    padding: 16px 16px 36px;
    font-size: 16px !important;
    border-radius: 8px 8px 8px 8px;
  }

  .ant-input-textarea-show-count::after {
    margin-right: 16px !important;
    transform: translateY(-30px);
  }
  .ant-form-item-control-input-content > input {
    padding: 13px 16px;
    background: #F7F8FA;
    border-radius: 8px 8px 8px 8px;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-input-wrap > input {
    padding: 13px 16px;
    background: #F7F8FA;
    height: 48px;
    width: 100%;
    border-radius: 8px;
  }


  .ant-checkbox-wrapper {
    font-size: 16px;
  }
  .ant-switch-checked {
    background: #30C252 !important;
  }
  .ant-switch {
    min-width: 48px;
    height: 28px;
  }
  .ant-switch-handle {
    width: 24px;
    height: 24px;
  }
  .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 26px);
  }
  .ant-switch-handle::before {
    border-radius: 50%;
  }
}

.antd-form-inline {
  .ant-form-item-row {
    flex-direction: row !important;
    justify-content: space-between;
    .ant-form-item-label, .ant-form-item-control {
      max-width: none !important;
      flex: none !important;
    }
    .ant-form-item-control {
      width: 44px !important;
    }
  }
}

.ant-btn {
    border-radius: 100px 100px 100px 100px !important;
    width: 100% !important;
    height: 48px !important;
    font-size: 16px !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    line-height: 38px !important;
}

.antd-form-inline {
  .ant-switch {
    transform: translateY(-7px);
  }
}
.ant-btn-primary[disabled] {
  background: #3879FC !important;
  color: #92B5FE !important;
}
.ant-form-item-explain-error {
  margin-top: 4px;
  margin-bottom: 24px;
}