.loading {
  width: 100vw;
  height: 100vh;
  z-index: 3;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .default_icon {
    width: 50px;
    height: 50px;
    border: 3px solid #666;
    border-radius: 50%;
    position: relative;
    animation: loading 1.5s infinite linear;
    .point {
      display: block;
      width: 16px;
      height: 16px;
      left: -3px;
      background: #666;
      border-radius: 50%;
      position: absolute;
      top: 0px;
    }
  }
  .custom_icon {
    display: inline-block;
    animation: loading 1.5s infinite linear;
  }
  .text {
    font-size: 20px;
    color: #666;
    margin-top: 16px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
