html,
body,
h1,
h2,
h3,
h4,
p,
em,
i,
div,
ul,
li,
ol,
dl,
dt,
dd,
a,
button,
textarea,
input {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  font-style: normal;
  outline: none;
}

html {
  font-size: 19.2px;
}

// @media only screen and (max-width: 1440px) {
//     html {
//         font-size: 14.40px;
//     }
// }

@media only screen and (max-width: 1160px) {
  html {
    font-size: 13.66px;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC, "Microsoft YaHei", 微软雅黑,
    "MicrosoftJhengHei", "Arial", "sans-serif";
}

a {
  text-decoration: none;
}

img {
  vertical-align: text-top;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}

button,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.overview {
  width: 100%;
  overflow: auto; // BFC
}

.width {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

#root {
  position: relative;
}

/* 弹窗遮罩层 */
.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.message {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  -webkit-animation: show 0.15s linear;
  animation: show 0.15s linear;
  font-size: 14px;
  line-height: 18px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .notice-logo {
    width: 36px;
    height: 36px;
    display: inline-block;
    background: url(~@assets/images/notice-icon.png) no-repeat;
    background-size: contain;
  }
  img {
    width: 36px;
    height: 36px;
    display: inline-block;
  }
  & > p {
    margin-top: 8px;
    margin-bottom: 0px;
  }
}

/* 设定全局滚动条样式 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

// 字号
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs24 {
  font-size: 24px;
}
.fs30 {
  font-size: 30px;
}
.fs32 {
  font-size: 32px;
}
.fs52 {
  font-size: 52px;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.lh400 {
  line-height: 400px;
}

// 字体颜色
.grayTitle {
  color: rgba(255, 255, 255, 0.6);
}
.graySub {
  color: rgba(255, 255, 255, 0.4);
}
.grayText {
  color: #898CA9;
}

// 边距
// top
.mt4 {
  margin-top: 8px;
}
.mt8 {
  margin-top: 8px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}
.mt32 {
  margin-top: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mt64 {
  margin-top: 64px;
}

.pt8 {
  padding-top: 8px;
}
.pt12 {
  padding-top: 12px;
}
.pt16 {
  padding-top: 16px;
}
.pt24 {
  padding-top: 24px;
}
.pt32 {
  padding-top: 32px;
}

// left
.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml24 {
  margin-left: 24px;
}
.ml32 {
  margin-left: 32px;
}

// bottom
.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb32 {
  padding-bottom: 32px;
}

// 布局对齐方式
.df {
  display: flex;
}
.jcsb {
  display: flex;
  justify-content: space-between;
}
.jcss {
  display: flex;
  justify-content: start;
}
.jcsa {
  display: flex;
  justify-content: space-around;
}
.jcfe {
  display: flex;
  justify-content: flex-end;
}
.colcenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.rowcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.tac {
  text-align: center;
}
