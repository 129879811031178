.adm-tabs-header {
  padding-left: 8px !important;
  display: inline-block !important;
  border-bottom: none !important;
}
.adm-tabs-tab-line {
  --active-line-height: 3px !important;
  --active-line-color: #3879FC !important;
  --active-line-border-radius: 6px !important;
}
.adm-tabs-tab {
  font-family: PingFang SC-Medium, PingFang SC !important;
  font-weight: 500 !important;
  --title-font-size: 14px !important;
  color: #9B9FA8;
}
.adm-tabs-tab-active {
  --active-title-color: #3879FC !important;
}
// .adm-tabs-tab-wrapper-stretch {
//   flex: 0 0 33% !important;
// }
.adm-list {
  --border-top: none !important;
  --border-bottom: none !important;
}

.adm-toast-main {
  background: rgba(0,0,0,0.8) !important;
  // padding: 18px 12px;
  // min-width: 104px;
  // min-height: 104px;
}
.adm-toast-mask .adm-toast-main-icon {
  padding: 20px !important;
  min-width: 104px !important;
}
.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  margin-bottom: 3px !important;
}
.adm-infinite-scroll {
  padding: 0 !important;
}
.adm-ellipsis {
  & > a {
    display: block !important;
  }
}
.adm-dialog-body:not(.adm-dialog-with-image) {
  padding-top: 24px !important;
}
.adm-dialog-content {
  padding: 0 24px 24px !important;
  width: 291px;
  font-size: 14px !important;
  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  font-size: 18px!important;
  color: #3879FC;
}
.adm-center-popup-body {
  border-radius: 12px !important;
}
.adm-center-popup .adm-center-popup-mask {
  background: rgba(0,0,0,0.4) !important;

}